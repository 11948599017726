<template>
    <validation-observer ref="observer">
        <form>
            <v-row>
                <v-col cols="6">
                    <validation-provider v-slot="{ errors }" name="Branch Number" rules="required|numeric">
                        <v-text-field v-model="localItem.number" :error-messages="errors"
                                      :label="$vuetify.lang.t('$vuetify.pages.site.branchNumber')"
                                      :min="0" outlined type="number"/>
                    </validation-provider>
                </v-col>
                <v-col cols="6">
                    <validation-provider v-slot="{ errors }" name="Branch Name (EN)" rules="required|min:2|max:30">
                        <v-text-field v-model="localItem.brand_name_en" :error-messages="errors"
                                      :label="$vuetify.lang.t('$vuetify.pages.site.branchNameEn')" outlined/>
                    </validation-provider>
                </v-col>
                <v-col cols="6">
                    <validation-provider v-slot="{ errors }" name="Branch Name (AR)" rules="required|min:2|max:30">
                        <v-text-field v-model="localItem.brand_name_ar" :error-messages="errors"
                                      :label="$vuetify.lang.t('$vuetify.pages.site.branchNameAr')" outlined/>
                    </validation-provider>
                </v-col>
                <v-col cols="6">
                    <validation-provider v-slot="{ errors }" name="Area" rules="required|min:2|max:50">
                        <v-text-field v-model="localItem.area" :error-messages="errors"
                                      :label="$vuetify.lang.t('$vuetify.pages.site.area')" outlined/>
                    </validation-provider>
                </v-col>
                <v-col cols="6">
                    <validation-provider v-slot="{ errors }" name="Address" rules="required|min:2|max:300">
                        <v-text-field v-model="localItem.address" :error-messages="errors"
                                      :label="$vuetify.lang.t('$vuetify.pages.site.address')" outlined/>
                    </validation-provider>
                </v-col>
                <v-col cols="6">
                    <validation-provider v-slot="{ errors }" name="Manager Name" rules="required|min:2|max:30">
                        <v-text-field v-model="localItem.manager_name" :error-messages="errors"
                                      :label="$vuetify.lang.t('$vuetify.pages.site.managerName')"
                                      outlined/>
                    </validation-provider>
                </v-col>
                <v-col cols="6">
                    <validation-provider v-slot="{ errors }" name="Manager Phone Number" rules="required">
                        <v-text-field v-model="localItem.manager_phone_number" :error-messages="errors"
                                      :label="$vuetify.lang.t('$vuetify.pages.site.managerPhoneNumber')" outlined/>
                    </validation-provider>
                </v-col>
            </v-row>
        </form>
    </validation-observer>
</template>

<script>
import {ValidationObserver} from 'vee-validate'
import Branch from "@/modules/sites/models/branch";

export default {
    name: "KurccBranchDialog",
    props: {
        item: {
            type: Object,
            required: true
        },
    },
    components: {
        ValidationObserver,
    },
    data() {
        return {
            submittingData: false
        }
    },
    computed: {
        localItem: {
            get() {
                return this.item;
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    },
    watch: {
        showBranchDialog(val) {
            if (!val) {
                this.$refs.observer?.reset()
                this.localItem = new Branch()
            }
        }
    }
}
</script>
