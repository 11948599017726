var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer"},[_c('form',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Branch Number","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$vuetify.lang.t('$vuetify.pages.site.branchNumber'),"min":0,"outlined":"","type":"number"},model:{value:(_vm.localItem.number),callback:function ($$v) {_vm.$set(_vm.localItem, "number", $$v)},expression:"localItem.number"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Branch Name (EN)","rules":"required|min:2|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$vuetify.lang.t('$vuetify.pages.site.branchNameEn'),"outlined":""},model:{value:(_vm.localItem.brand_name_en),callback:function ($$v) {_vm.$set(_vm.localItem, "brand_name_en", $$v)},expression:"localItem.brand_name_en"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Branch Name (AR)","rules":"required|min:2|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$vuetify.lang.t('$vuetify.pages.site.branchNameAr'),"outlined":""},model:{value:(_vm.localItem.brand_name_ar),callback:function ($$v) {_vm.$set(_vm.localItem, "brand_name_ar", $$v)},expression:"localItem.brand_name_ar"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Area","rules":"required|min:2|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$vuetify.lang.t('$vuetify.pages.site.area'),"outlined":""},model:{value:(_vm.localItem.area),callback:function ($$v) {_vm.$set(_vm.localItem, "area", $$v)},expression:"localItem.area"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Address","rules":"required|min:2|max:300"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$vuetify.lang.t('$vuetify.pages.site.address'),"outlined":""},model:{value:(_vm.localItem.address),callback:function ($$v) {_vm.$set(_vm.localItem, "address", $$v)},expression:"localItem.address"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Manager Name","rules":"required|min:2|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$vuetify.lang.t('$vuetify.pages.site.managerName'),"outlined":""},model:{value:(_vm.localItem.manager_name),callback:function ($$v) {_vm.$set(_vm.localItem, "manager_name", $$v)},expression:"localItem.manager_name"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Manager Phone Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$vuetify.lang.t('$vuetify.pages.site.managerPhoneNumber'),"outlined":""},model:{value:(_vm.localItem.manager_phone_number),callback:function ($$v) {_vm.$set(_vm.localItem, "manager_phone_number", $$v)},expression:"localItem.manager_phone_number"}})]}}])})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }